import React from "react";
import Icon, { IconProps } from "./Icon";
import { colors } from "@puzzle/theme";

export default function Calculator2({
  viewBox = "0 0 20 20",
  width = 20,
  height = 20,
  fill = "none",
  color = "currentColor",
  stroke = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} fill={fill} {...iconProps} viewBox={viewBox}>
      <path
        d="M3.95837 6.45817V16.8748C3.95837 17.3351 4.33147 17.7082 4.79171 17.7082H15.2084C15.6686 17.7082 16.0417 17.3351 16.0417 16.8748V6.45817M3.95837 6.45817H16.0417M3.95837 6.45817V3.12484C3.95837 2.6646 4.33147 2.2915 4.79171 2.2915H15.2084C15.6686 2.2915 16.0417 2.6646 16.0417 3.12484V6.45817"
        stroke={stroke}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91667 10.8332C8.26184 10.8332 8.54167 10.5533 8.54167 10.2082C8.54167 9.86299 8.26184 9.58317 7.91667 9.58317C7.57149 9.58317 7.29167 9.86299 7.29167 10.2082C7.29167 10.5533 7.57149 10.8332 7.91667 10.8332ZM7.91667 14.5832C8.26184 14.5832 8.54167 14.3033 8.54167 13.9582C8.54167 13.613 8.26184 13.3332 7.91667 13.3332C7.57149 13.3332 7.29167 13.613 7.29167 13.9582C7.29167 14.3033 7.57149 14.5832 7.91667 14.5832ZM12.0833 10.8332C12.4285 10.8332 12.7083 10.5533 12.7083 10.2082C12.7083 9.86299 12.4285 9.58317 12.0833 9.58317C11.7382 9.58317 11.4583 9.86299 11.4583 10.2082C11.4583 10.5533 11.7382 10.8332 12.0833 10.8332ZM12.0833 14.5832C12.4285 14.5832 12.7083 14.3033 12.7083 13.9582C12.7083 13.613 12.4285 13.3332 12.0833 13.3332C11.7382 13.3332 11.4583 13.613 11.4583 13.9582C11.4583 14.3033 11.7382 14.5832 12.0833 14.5832Z"
        fill={colors.white}
        stroke={stroke}
        strokeWidth="0.416667"
      />
    </Icon>
  );
}
