import React from "react";
import Icon, { IconProps } from "./Icon";
import { Colors } from "./colors";
import { colors } from "@puzzle/theme";

export default function Revenue({ viewBox = "0 0 24 24", active, ...iconProps }: IconProps) {
  return (
    <Icon size={24} {...iconProps} viewBox={viewBox}>
      <path
        d="M19.875 6.9375C19.875 6.65625 19.5938 6.375 19.3125 6.375H15.1641C14.3906 6.375 14.0039 7.28906 14.5664 7.81641L15.6914 8.97656L13.125 11.543L10.5234 8.97656C10.3477 8.80078 9.99609 8.66016 9.75 8.66016C9.46875 8.66016 9.11719 8.80078 8.94141 8.97656L6.51562 11.4023C6.44531 11.4727 6.375 11.6484 6.375 11.7891C6.375 11.9297 6.44531 12.1055 6.51562 12.1758L7.32422 12.9844C7.39453 13.0547 7.57031 13.1602 7.71094 13.1602C7.85156 13.1602 8.02734 13.0547 8.09766 12.9844L9.75 11.3672L12.3164 13.9336C12.4922 14.1094 12.8438 14.2852 13.0898 14.2852C13.3711 14.2852 13.7227 14.1094 13.8984 13.9336L17.2734 10.5586L18.4336 11.7188C18.9609 12.2461 19.875 11.8594 19.875 11.1211V6.9375Z"
        fill={active ? Colors.Active : colors.gray500}
      />
      <path
        d="M21 17.0625C21 16.7812 20.7188 16.5 20.4375 16.5H5.25V5.8125C5.25 5.53125 4.96875 5.25 4.6875 5.25H3.5625C3.24609 5.25 3 5.53125 3 5.8125V17.625C3 18.2578 3.49219 18.75 4.125 18.75H20.4375C20.7188 18.75 21 18.5039 21 18.1875V17.0625Z"
        fill={colors.gray300}
      />
    </Icon>
  );
}
